<template>
    <div>
        <h1 class="title bg-dark">Parcours</h1>
        <img src="@/assets/Web-Design.jpg" class="img-fuild" alt="banner">
        <div style="width: 100%; height: 10px; background-color: lightblue;"></div>   
    </div>
</template>

<script>
export default {
  name: 'BannerView',
}
</script>

<style scoped>
    img{
        width: 100%;
    }
    .title{
        color: lightblue;
        position: absolute;
        top: 6rem;
        padding-left: 1rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-right: 12.1rem;
        right: 0;
    }
</style>