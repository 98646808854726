<template>
  <div class="home">
    <Banner/>
    <div class="content">
      <b-card bg-variant="light" title="Manager de mots-clés" class="mt-5 mb-2 mx-3" style="width: 31rem; padding: 1rem; height: 450px; max-width: 31rem;">
        <b-card-text>Cette application web permet de réorganiser des mots-clés entrés par les utilisateurs du plus pertinent au moins pertinent par un simple glisser / déposer.</b-card-text>
        <b-card-text>Testez le <router-link to="/KeyWordManager">ici</router-link></b-card-text>
        <b-card-img :src="require('@/assets/KeyWordManager.jpg')" img-width="195" img-fluid img-bottom></b-card-img>
      </b-card>
      <b-card bg-variant="light" title="Air-line" class="mt-5 mb-2 mx-3" style="width: 31rem; padding: 1rem; height: 450px; max-width: 31rem;">
        <b-card-text>Cette application web permet de Chercher / Créer / Modifier / Supprimer des voyages elle sert principalement de site vitrine pour une agence de voyage.</b-card-text>
        <b-card-img :src="require('@/assets/AirLine.jpg')" img-width="195" img-fluid img-bottom></b-card-img>
      </b-card>
      <b-card bg-variant="light" title="Site web Budget Arnas" class="mt-5 mb-2 mx-3" style="width: 31rem; padding: 1rem; height: 450px; max-width: 31rem;">
        <b-card-text>Cette page web permet aux citoyens de la mairie d'Arnas de gérer le budget de différents postes de dépances de la mairie.<br>
        Les administrateurs ont également la capacité d'ajouter / modifier / supprimer des postes de dépances et avoir un récapitulatif des budgets alloués par les citoyens.</b-card-text>
        <b-card-img :src="require('@/assets/Arnas.jpg')" img-width="195" img-fluid img-bottom></b-card-img>      
      </b-card>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Banner from '@/components/BannerProjets.vue'
export default {
  name: 'HomeView',
  components: {
    Banner,
  }
}
</script>
<style scoped>
  .content{
    align-items: center;
    justify-content: center;
    display: flex;
  }
</style>