<template>
  <div class="home">
    <Banner/>
    <div class="content">
      <b-card bg-variant="light" title="Langages" class="mt-5 mb-2 mx-3" style="width: 50rem; height: 14rem; padding:1rem; max-width: 45rem;">
        <div class="ProgressBar mx-2"><b-card-text class="BarText">PHP, MYSQL</b-card-text><b-progress :value=valuePHP striped :animated="animate" class="w-100 mx-2" readonly></b-progress></div>
        <div class="ProgressBar mx-2"><b-card-text class="BarText">CSS</b-card-text><b-progress :value=valueCSS striped :animated="animate" class="w-100 mx-2" readonly></b-progress></div>
        <div class="ProgressBar mx-2"><b-card-text class="BarText">JAVASCRIPT</b-card-text><b-progress :value=valueJS striped :animated="animate" class="w-100 mx-2" readonly></b-progress></div>
        <div class="ProgressBar mx-2"><b-card-text class="BarText">JAVA</b-card-text><b-progress :value=valueJ striped :animated="animate" class="w-100 mx-2" readonly></b-progress></div>
      </b-card>
      <b-card bg-variant="light" title="Framework" class="mt-5 mb-2 mx-3" style="width: 50rem; height: 14rem; padding:1rem; max-width: 45srem;">
        <div class="ProgressBar mx-2"><b-card-text class="BarText">Vue</b-card-text><b-progress :value=valueVue striped :animated="animate" class="w-100 mx-2" readonly></b-progress></div>
        <div class="ProgressBar mx-2"><b-card-text class="BarText">Symphony</b-card-text><b-progress :value=valueSym striped :animated="animate" class="w-100 mx-2" readonly></b-progress></div>
        <div class="ProgressBar mx-2"><b-card-text class="BarText">Bootstrap</b-card-text><b-progress :value=valueBoo striped :animated="animate" class="w-100 mx-2" readonly></b-progress></div>
      </b-card>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Banner from '@/components/BannerCompetence.vue'
export default {
  name: 'HomeView',
  components: {
    Banner,
  },
  data() {
    return {
      valuePHP: 50,
      valueCSS: 25,
      valueJS: 50,
      valueJ: 50,
      valueVue: 50,
      valueSym: 25,
      valueBoo: 25,
      animate: true,
    }
  },
}
</script>

<style scoped>
.ProgressBar{
  display: inline-flex;
  width: 100%;
}
.BarText{
  width: 10rem;
  text-align: center;
  align-items: center;
}
.content{
   justify-content: center;
   display: flex;
   height: 30rem;
 }
</style>