<template>
  <div class="home">
    <Banner/>
    <div class="content">
      <b-card bg-variant="light" title="OLM Communication" class="mt-5 mb-2 mx-3" style="width: 50rem; padding:1rem; max-width: 47.5rem; height: 450px;">
        <b-card-text>
          Développement d'une application Java de gestion de mots-clé.<br>
          L'Entreprise OLM Communication souhaitait avoir une application permettant de réorganiser plus efficacement les mots-clé inserés dans les métadonnées de ses images, car cette action est généralement longue et fastidieuse.<br>
          Ma mission était de développer une application Java permettant de gérer les mots-clés de manière simple et intuitive.<br>
          Je l'ai par la suite réecrite avec le Framework VueJS pour créer une application web.
        </b-card-text>
        <b-card-img :src="require('@/assets/Logo-Olivier-Le-Moal-195px.png')" img-width="195" img-fluid img-bottom></b-card-img>
      </b-card>
      <b-card bg-variant="light" title="ECE" class="mt-5 mb-2 mx-3" style="width: 50rem; padding:1rem; max-width: 47.5rem; height: 450px;">
        <b-card-text>
          Développement d'une application web de planning pour les employés.<br>
          L'Entreprise ECE souhaitait améliorer son système de planning car elle utilisait auparavant un tableau Excel.<br>
          Ma mission était de développer une application web permettant aux employés de gérer leurs plannings de manière simple et intuitive.
        </b-card-text>
        <b-card-img :src="require('@/assets/Logo-ECE-195px.png')" img-width="195" img-fluid img-bottom></b-card-img>
      </b-card>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Banner from '@/components/BannerStages.vue'
export default {
  name: 'HomeView',
  components: {
    Banner,
  }
}
</script>

<style scoped>
  img{ 
    width: 15rem;
  }
  .content{
    align-items: center;
    justify-content: center;
    display: flex;
  }
</style>
