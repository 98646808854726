<template>
    <div>
        <div class="title bg-dark"><h1>Compétences</h1></div>
        <img src="@/assets/Programming.jpg" class="img-fuild" alt="banner">
        <div style="width: 100%; height: 10px; background-color: lightblue;"></div>   
    </div>
</template>

<script>
export default {
  name: 'BannerView',
}
</script>

<style scoped>
    img{
        width: 100%;
    }
    .title{
        color: lightblue;
        position: absolute;
        top: 6rem;
        right: 0;
        padding-left: 1rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-right: 7.5rem;
    }
</style>