<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark sticky-top">
    <div class="container-fluid">
      <router-link class="navbar-brand" to="/">Theo Le Moal</router-link>
      <div class="navbar-collapse" id="navbarNavAltMarkup">
        <div class="navbar-nav"> 
          <div><router-link class="nav-link active" aria-current="page" to="/">Accueil</router-link></div>
          <div><router-link class="nav-link active" aria-current="page" to="/competences">Compétences</router-link></div>
          <div><router-link class="nav-link active" aria-current="page" to="/parcours">Parcours</router-link></div>
          <div><router-link class="nav-link active" aria-current="page" to="/projets">Projets</router-link></div>
          <div><router-link class="nav-link active" aria-current="page" to="/stages">Stages</router-link></div>
          <div><router-link class="nav-link active" aria-current="page" to="/contact">Contact</router-link></div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'HelloWorld',
}
</script>