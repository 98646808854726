<template>
  <div>
    <Banner/>
    <div class="content">
      <b-card bg-variant="light" title="Service civique (Médiation numérique)" class="mt-5 mx-3 mb-2" style="width: 50rem; padding:1rem; height: 450px; max-width: 31rem;">
        <b-card-text>2021-2022</b-card-text>
        <b-card-text>La B@se formation, médiation numérique</b-card-text>
        <b-card-img :src="require('@/assets/Logo-Service-Civique-195px.png')" img-fluid img-bottom></b-card-img>
      </b-card>
      <b-card bg-variant="light" title="BTS SIO (Services Informatiques aux Organisations)" class="mt-5 mb-2 mx-3" style="width: 50rem; padding:1rem; height: 450px; max-width: 31rem;">
        <b-card-text>2019-2021</b-card-text>
        <b-card-text>Centre Scolaire Notre Dame, Option SLAM (Solutions Logicielles et Applications Métiers).</b-card-text>
        <b-card-img :src="require('@/assets/LogoCSND.png')" img-fluid img-bottom></b-card-img>
      </b-card>
      <b-card bg-variant="light" title="BAC profesionnel SN (Systèmes Numeriques)" class="mt-5 mb-2 mx-3" style="width: 50rem; padding:1rem; height: 450px; max-width: 31rem;">
        <b-card-text>2018-2019</b-card-text>
        <b-card-text>Lycée Saint Louis, Option ARED (Audiovisuels, Réseau et Équipement Domestiques).</b-card-text>
        <b-card-img :src="require('@/assets/Logo-Saint-Louis-195px.png')" img-fluid img-bottom></b-card-img>
      </b-card>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Banner from '@/components/BannerParcours.vue'
export default {
  name: 'HomeView',
  components: {
    Banner,
  }
}
</script>
<style scoped>
 .content{
   align-items: center;
   justify-content: center;
   display: flex;
 }
</style>

