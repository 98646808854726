import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import CompetencesView from '../views/CompetencesView'
import ParcoursView from '../views/ParcoursView'
import ProjetsView from '../views/ProjetsView.vue'
import ContactView from '../views/ContactView'
import StagesView from '../views/StagesView'
import keyWordManager from '../views/keyWordManager'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/competences',
    name: 'competences',
    component: CompetencesView
  },
  {
    path: '/parcours',
    name: 'parcours',
    component: ParcoursView
  },
  {
    path: '/projets',
    name: 'projets',
    component: ProjetsView
  },
  {
    path: '/keyWordManager',
    name: 'keyWordManager',
    component: keyWordManager
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView
  },
  {
    path: '/stages',
    name: 'stages',
    component: StagesView
  },
  {
    path: '*',
    redirect: '/'
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes

  
})

export default router