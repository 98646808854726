<template>
	<div>
		<Banner/>
		<div class="content">
			<div class="mt-4 mx-3 mb-2" style="width: 101rem; padding:1rem; max-width: 101rem; align-items: center; justify-content: center; display: flex;">
				<img src="@/assets/Profil.png" class="mx-3">
				<div>
					<h1>Bienvenue sur mon portfolio</h1>
					<p>Theo Le Moal <br>
					11, Clos Mistral - 26250 Livron-sur-Drôme <br>
					Tél. : 06.72.32.83.28 <br>
					Email : contact@theo-le-moal.fr <br><br>
					Theo Le Moal, titulaire d'un BTS SIO (Système Informatique aux Organisations) option SLAM (Solutions Logicielles et Applications Métiers).<br><br>
					Ce portfolio a été mis en place dans le but de vous présenter mes compétences et motivations.<br><br>
					Mes connaissances personnelles ainsi que certains de mes travaux et autres ressources utiles seront disponibles via cette plateforme par laquelle vous pourrez donc juger mon travail et mes aptitudes.</p>
					<p>Vous retrouverez mon CV au format PDF ci-dessous.</p>
					<a href="CV_Theo_Le_Moal_2022.pdf" target="_blank"><img class="pdf" alt=""></a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// @click.prevent="downloadItem({url: '', label: 'CV-Theo-Le-Moal.pdf',})"
/* eslint-disable */
// @ is an alias to /src
import Banner from '@/components/BannerHome.vue'
import axios from "axios"
export default {
  name: 'HomeView',
  components: {
	Banner,
  },
  methods: {
	  async downloadItem({ url, label }){
		  const response = await axios.get(url, { responseType : "blob" })
		  const blob = new Blob([response.data], { type: "application/pdf" })
		  const link = document.createElement("a")
		  link.href = URL.createObjectURL(blob)
		  link.download = label
		  link.click()
		  URL.revokeObjectURL(link.href)
	  }
  },
}
</script>

<style scoped>
  .content{
    align-items: center;
    justify-content: center;
    display: flex;
  }
  a{
	  border-style: none;
	  text-decoration: none;
  }
  .pdf{
	  border-style: none;
	  width: 60px;
	  height: 74px;
	  background-image: url("@/assets/Pdf-Normal.png");
	  background-color: transparent;
	}
  .pdf:hover{
	  background-image: url("@/assets/Pdf-Survol.png");
	  background-color: transparent;
	}
</style>