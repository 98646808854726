<template>
  <section class="bg-dark p-1 pt-1" style="bottom: 0">
    <section class="text-white">
      <div style="text-align: center; display: inline-flex; width: 100%;">
        <div style="margin-left: 0.5rem; vertical-align: middle;">
            <p>Ⓒ : theo-le-moal.fr</p> 
        </div>
        <div style="margin-left: 65rem; vertical-align: middle;">
            <p>Tél. : 06 72 32 83 28</p>
        </div>
        <div style="margin-left: 5rem; vertical-align: middle;">
            <p>Email : contact@theo-le-moal.fr</p>
        </div>
      </div> 
    </section>
  </section>
</template>

<script>
export default {
  name: 'HelloWorld',
}
</script>

<style scoped>
</style>