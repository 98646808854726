import Vue from 'vue'
import App from './App.vue'
import router from './router'
import "bootstrap/dist/css/bootstrap.min.css"
import 'bootstrap-vue/dist/bootstrap-vue.css'
import * as Bootstrap from "bootstrap"
import BootstrapVue from 'bootstrap-vue'
import {BootstrapVueIcons}from 'bootstrap-vue'
import {CardPlugin} from 'bootstrap-vue'
import {ProgressPlugin} from 'bootstrap-vue'
Vue.use(BootstrapVue)
Vue.use(Bootstrap)
Vue.use(CardPlugin)
Vue.use(ProgressPlugin)
Vue.use(BootstrapVueIcons)
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
