<template>
  <div id="app">
    <HelloWorld/>
    <router-view/>
    <Footer style="bottom: 0;"/>
  </div>
</template>

<script>
import HelloWorld from '@/components/HelloWorld.vue'
import Footer from '@/components/Footer.vue'
export default {
  components: {
    HelloWorld,
    Footer
  }
}
</script>

<style>
.Title{
  position: absolute;
  top: 12.5rem;
  left: 1rem;
  color: white;
}
html{
  background-color: #ebedf4;
}
.content{
  background-color: #ebedf4;
  width: 100%;
}
</style>