<template>
  <div class="content">
    <div class="text-center container">
      <div class="mt-1 mb-2 d-flex align-content-stretchb h-25 flex-wrap">
        <b-card class="" style="width: 80%; background-color: lightblue;">
          <textarea class="w-100 form-control" name="inputWords" id="" rows="4" v-model="motsList" @keyup="onChange(motsList)"></textarea>
        </b-card>
        <b-card class="ml-1" style="width: 20%; background-color: lightblue;">
          <button class="btn mb-1 h-50 w-100" style="background-color: white" @click="copieToClipboard">Copier</button><br>
          <button class="btn h-50 w-100" style="background-color: white" @click="supressionDoublon">Enlever les doublons</button>
        </b-card>
      </div>
      <draggable ghost-class="ghost" @onEnd="onEnd" v-model="myArray" style="height: 637px;" :list="myArray" :options="{delay:0, animation:150}">
        <transition-group class="ui cards d-flex align-content-stretchb h-25 flex-wrap">
          <div v-for="element in myArray" :key="element.id" :position="element.id" @dragend="positionChange" class="card">
            {{element.name}}
          </div>
        </transition-group>
      </draggable>
    </div>
    <div class="w-100 text-center" style="height: 75px; background-color: lightgrey;">
      <p>{{myArray.length}} Mot(s)</p>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
export default {
  components: {
    draggable
  },
  data() {
    return {
      motsList: 'excellence; increase; customer; retention; engagement; satisfaction; experience; improve; performance; consumer; support; service; good; positive; opinion; rating; quality; improvement; client; business; concept; excellent; evaluation; hand; marketing; research; happy; scale; horizontal; like; analyzing; management; conceptual',
      temp1: [],
      temp2: '',
      myArray: [{name:'excellence', id:0}, {name:'increase', id:1}, {name:'customer', id:2}, {name:'retention', id:3}, {name:'engagement', id:4}, {name:'satisfaction', id:5}, {name:'experience', id:6}, {name:'improve', id:7}, {name:'performance', id:8}, {name:'consumer', id:9}, {name:'support', id:10}, {name:'service', id:11}, {name:'good', id:12}, {name:'positive', id:13}, {name:'opinion', id:14}, {name:'rating', id:15}, {name:'quality', id:16}, {name:'improvement', id:17}, {name:'client', id:18}, {name:'business', id:19}, {name:'concept', id:20}, {name:'excellent', id:21}, {name:'evaluation', id:22}, {name:'hand', id:23}, {name:'marketing', id:24}, {name:'research', id:25}, {name:'happy', id:26}, {name:'scale', id:27}, {name:'horizontal', id:28}, {name:'like', id:29}, {name:'analyzing', id:30}, {name:'management', id:31}, {name:'conceptual', id:32}],
      newIndex: '',
      oldIndex: ''
    }
  },
  methods: {
    onLoad: function() {
      const cards = document.querySelectorAll("div.card");
      for (let i = 0; i < cards.length; i++) {
        if(i >= 2 && i <= 9){
          cards[i].style.backgroundColor = 'green'
        }else if( i >= 8){
          cards[i].style.backgroundColor = '#606060'
        }
      }
    },
    onEnd: function(evt){
      this.newIndex = evt.newIndex
      this.oldIndex = evt.oldIndex
    },
    onChange: function(motsList){
      const cards = document.querySelectorAll("div.card");
      for (let i = 0; i < cards.length; i++) {
        if(i >= 2 && i < 9){
             cards[i].style.backgroundColor = 'green'
        }else if( i > 8){
          cards[i].style.backgroundColor = '#606060'
        }
      }
      this.motsList = this.motsList.replaceAll(",", ";")
      this.motsList = this.motsList.replaceAll("; ;", ";")
      this.motsList = this.motsList.replaceAll(";;", ";")
      this.motsList = this.motsList.replaceAll("  ", " ")
      this.temp1 = motsList.split(";")
      this.myArray = []
      for (let index = 0; index < this.temp1.length; index++) {
        this.myArray.push({name: this.temp1[index], id: index})
      }
      if (motsList == "" || motsList == " ") {
        this.myArray = []
      }
    },
    positionChange: function(){
      const cards = document.querySelectorAll("div.card");
      for (let i = 0; i < cards.length; i++) {
        if(i >= 2 && i < 9){
          cards[i].style.backgroundColor = 'green'
        }else if( i > 8){
          cards[i].style.backgroundColor = '#606060'
        }
      }
      this.temp1 = []
      for (let i = 0; i < this.myArray.length; i++) {
        this.temp1.push(this.myArray[i].name)
      }
      this.motsList = this.temp1.join("; ")
      this.motsList = this.motsList.replaceAll(",", ";")
      this.motsList = this.motsList.replaceAll("; ;", ";")
      this.motsList = this.motsList.replaceAll(";;", ";")
      this.motsList = this.motsList.replaceAll("  ", " ")
    },
    copieToClipboard: function(){
      navigator.clipboard.writeText(this.motsList)
    },
    supressionDoublon: function(){
      this.myArray = this.myArray.filter((e, i, self) => i === self.findIndex((t) => t.name === e.name))
      this.temp1 = []
      for (let i = 0; i < this.myArray.length; i++) {
        this.temp1.push(this.myArray[i].name)
      }
      this.motsList = this.temp1.join("; ")
      this.motsList = this.motsList.replaceAll(",", ";")
      this.motsList = this.motsList.replaceAll("; ;", ";")
      this.motsList = this.motsList.replaceAll(";;", ";")
      this.motsList = this.motsList.replaceAll("  ", " ")
    }
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.ui.cards{
	margin: 0.25%;
	position: relative;
}
nav{
  margin: 0;
  color: white;
}
.ui.cards .card{
  text-align: center;
  align-items: center;
  color: white;
  animation : 130;
	background-color: #606060;
	display: inline-flex;
	box-shadow: none;
	border: solid #ffffff 3px;
	border-radius: 15px;
	padding: 25px;
	margin: 0px;
	width: 14.25%;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
}
.form{
  display: flex;
  width: 100%;
}
.button{
  height: 65px;
  width: 65px;
  margin-right: 2.5%;
  margin-top: 2.5%;
}
.content{
  height: 54rem;
}
.ui.cards .card :nth-child(4n) {
  background-color: lime;
}
</style>
