<template>
  <div class="home">
    <Banner/>
    <div class="content">
      <b-card bg-variant="light" class="mt-5 mb-2 mx-3" style="width: 97rem; height: 4rem;justify-content: center; text-align: center; align-items: center; padding:1rem; max-width: 97rem;">
        <b-card-text>Contactez moi à cette adresse mail : contact@theo-le-moal.fr</b-card-text>
      </b-card>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Banner from '@/components/BannerContact.vue'
export default {
  name: 'HomeView',
  components: {
    Banner,
  },
  data() {
    return {
      emailAddress: 'exemple@domaine.com',
      name: 'Mark',
      text: 'Ceci est un message'
    }
  },
}
</script>

<style scoped>
  .content{
    justify-content: center;
    display: flex;
    height: 29rem;
  }
</style>